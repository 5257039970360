export function AnimatedButtonComponent() {
  const ANIMATED_BUTTONS = document.querySelectorAll('.animated-button-element');

  /**
   * Initialize the component
   */
  window.addEventListener('load', () => {
    handleButtonClick();
  });

  /**
   * Handle the button action.
   */
  function handleButtonClick() {
    ANIMATED_BUTTONS.forEach((button) => {
      if (button.type === 'submit') {
        return;
      }

      button.addEventListener('click', () => (window.location.href = button.dataset.href));
    });
  }
}

window.animatedButton = new AnimatedButtonComponent();
